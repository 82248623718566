var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"noPosition",staticClass:"noPosition",style:({
  width: _vm.wrapWidth + 'px',
  height: _vm.getImgWrapHeight(_vm.wrapWidth, _vm.imgList.length) + 'px',
}),attrs:{"id":"right"}},[_vm._l((_vm.signList),function(item,index){return [(item.signType == 4)?_vm._l((_vm.pageNum),function(num,index2){return _c('vue-draggable-resizable',{key:("" + index + index2 + (item.signType)),style:({
          transform: ("translate(" + (item.x) + "px, " + (_vm.calcPositionY(
            item.y,
            index2 + 1
          )) + "px)"),
        }),attrs:{"class-name":"my-drag-class4","x":item.x,"y":_vm.calcPositionY(item.y, index2 + 1),"w":_vm.getWidthAndHeight(item.width / _vm.pageNum),"h":_vm.getWidthAndHeight(item.height),"parent":true,"resizable":false},on:{"dragging":function (x, y) { return _vm.onDragging4({ x: x, y: y }, index, index2, item); },"dragstop":_vm.onDragStop}},[_c('div',{staticClass:"test"},[_c('i',{staticClass:"el-icon-delete2",on:{"click":function($event){$event.stopPropagation();return _vm.deleteOneSign(index)}}}),_c('div',{staticClass:"img-wrap",style:({
            width: ((_vm.getWidthAndHeight(item.width / _vm.pageNum)) + "px"),
            height: ((_vm.getWidthAndHeight(item.height)) + "px"),
            overflow: "hidden",
            position: 'relative',
          })},[_c('img',{style:({
              position: 'absolute',
              top: 0,
              height: '100%',
              transform: ("translate(-" + (_vm.getWidthAndHeight(
                item.width / _vm.pageNum
              ) *
                (num - 1)) + "px"),
            }),attrs:{"src":item.sealKey,"alt":""}})]),_c('div',{staticClass:"signTitle"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.textBecomeImg(_vm.SIGN_SEAL_TYPE_TEXT[item.signType], 14, '#000'),"alt":""}})])])])}):(item.signType === 3)?[_c('vue-draggable-resizable',{key:index,style:({
          transform: ("translate(" + (item.x) + "px, " + (_vm.calcPositionY(
            item.y,
            item.page
          )) + "px)"),
        }),attrs:{"class-name":"my-drag-class","x":item.x,"y":_vm.calcPositionY(item.y, item.page),"w":_vm.getWidthAndHeight(item.width),"h":_vm.getWidthAndHeight(item.height),"parent":true,"resizable":false},on:{"dragging":function (x, y) { return _vm.drag({ x: x, y: y }, index, item.signType, item, item.page); },"dragstop":_vm.onDragStop}},[_c('div',{staticClass:"test"},[_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteOneSign(index)}}}),(item.dateFlag)?_c('div',{staticClass:"time"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.currenDateBase64,"alt":""}}),_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){item.dateFlag = 0}}})]):_vm._e(),_c('img',{attrs:{"src":item.sealKey,"alt":""}}),_c('div',{staticClass:"signTitle"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.textBecomeImg(_vm.SIGN_SEAL_TYPE_TEXT[item.signType], 14, '#000'),"alt":""}})])])])]:[_c('vue-draggable-resizable',{key:index,style:({
          transform: ("translate(" + (item.x) + "px, " + (_vm.calcPositionY(
            item.y,
            item.page
          )) + "px)"),
          width:
            (item.resize ? item.width : _vm.getWidthAndHeight(item.width)) + 'px',
          height:
            (item.resize ? item.height : _vm.getWidthAndHeight(item.height)) +
            'px',
        }),attrs:{"class-name":"my-drag-class user-position","x":item.x,"y":_vm.calcPositionY(item.y, item.page),"w":_vm.getWidthAndHeight(item.width),"h":_vm.getWidthAndHeight(item.height),"minWidth":43,"minHeight":29,"lock-aspect-ratio":"","parent":true,"resizable":true,"handles":['br']},on:{"resizing":function (left, top, width, height) { return _vm.onSealResizing({
              left: left,
              top: top,
              width: width,
              height: height,
              index: index,
              page: item.page,
              item: item,
            }); },"resizestop":function (left, top, width, height) { return _vm.onSealResizeStop({
              left: left,
              top: top,
              width: width,
              height: height,
              index: index,
              page: item.page,
            }); },"dragging":function (x, y) { return _vm.drag({ x: x, y: y }, index, item.signType, item, item.page); },"dragstop":function (left, top) { return _vm.onDragStop({ left: left, top: top, item: item, page: item.page }); }}},[_c('div',{staticClass:"test"},[_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteOneSign(index)}}}),(item.dateFlag)?_c('div',{staticClass:"time"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.currenDateBase64,"alt":""}}),_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){item.dateFlag = 0}}})]):_vm._e(),_c('img',{attrs:{"src":item.sealKey,"alt":""}}),_c('div',{staticClass:"signTitle"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.textBecomeImg(_vm.SIGN_SEAL_TYPE_TEXT[item.signType], 14, '#000'),"alt":""}})])])])]]}),_vm._l((_vm.currentFile.oldPositionList),function(item,index){return [(item.signType == 4)?_vm._l((_vm.pageNum),function(num,index2){return _c('div',{key:index + 'signPosition2' + index2,class:'signPosition2',style:({
        right: '0px',
        top: _vm.calcPositionY(item.y, index2 + 1, _vm.ratio) + 'px',
        width: ((_vm.getWidthAndHeight(item.width / _vm.pageNum)) + "px"),
        height: ((_vm.getWidthAndHeight(item.height)) + "px"),
      })},[_c('img',{style:({
          transform: ("translate(-" + (_vm.getWidthAndHeight(item.width / _vm.pageNum) *
            (num - 1)) + "px"),
        }),attrs:{"src":item.sealKey,"alt":""}})])}):[_c('div',{key:index + 'oldPositionList',class:'signPosition',style:({
        left: item.x * _vm.ratio + 'px',
        top: _vm.calcPositionY(item.y, item.page, _vm.ratio) + 'px',
        width: ((_vm.getWidthAndHeight(item.width)) + "px"),
        height: ((_vm.getWidthAndHeight(item.height)) + "px"),
      })},[(item.dateFlag)?_c('div',{staticClass:"time"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.textBecomeImg(item.signDate, 14, '#000'),"alt":""}})]):_vm._e(),_c('img',{attrs:{"src":item.sealKey,"alt":""}})])]]}),_c('ul',{staticClass:"file-img-list"},[_vm._l((_vm.imgList),function(item,index){return _c('li',{key:index,staticClass:"file-img-item",class:{
      'seal4-bg': _vm.imgList.length > 1 && _vm.type === 2,
      'show-page-footer': _vm.isCurImgLoaded(index),
    }},[_c('img',{style:({ height: _vm.computedY + 'px' }),attrs:{"src":item,"alt":""},on:{"load":function($event){return _vm.onImgLoaded(index)}}}),(index < _vm.imgList.length - 1)?_c('div',{staticClass:"page-content"},[_c('SvgIcon',{staticClass:"page_icon icon_top_left",attrs:{"type":"icon_top_left"}}),_c('SvgIcon',{staticClass:"page_icon icon_top_right",attrs:{"type":"icon_top_right"}}),_c('SvgIcon',{staticClass:"page_icon icon_bottom_left",attrs:{"type":"icon_bottom_left"}}),_c('SvgIcon',{staticClass:"page_icon icon_bottom_right",attrs:{"type":"icon_bottom_right"}})],1):_vm._e()])}),(!_vm.currentFile.fileHtml)?[_vm._l((_vm.hasValueParamList),function(variable){return [(variable.dataType != 11)?_c('li',{key:variable.componentId,staticClass:"file-variable",style:({
          left: variable.x * _vm.ratio + 'px',
          top: _vm.calcPositionY(variable.y, variable.page, true) + 'px',
          width:
            variable.fontSize * _vm.ratio >= 12
              ? variable.width * _vm.ratio + 'px'
              : (variable.width * _vm.ratio) /
              ((variable.fontSize * _vm.ratio) / 12) +
              'px',
          height: variable.height * _vm.ratio + 'px',
          fontSize:
            variable.fontSize * _vm.ratio >= 12
              ? variable.fontSize * _vm.ratio + 'px'
              : 12 + 'px',
          fontFamily: _vm.getCurFontFamilyByValue(variable.font).name,
        })},[_c('div',{style:({
            transform: ("scale(" + (variable.fontSize * _vm.ratio >= 12
                ? 1
                : (variable.fontSize * _vm.ratio) / 12) + ")"),
            display: 'inline-block',
            transformOrigin: '0 0',
          })},[_vm._v(" "+_vm._s(variable.varValue ? variable.varValue : variable.varTitle)+" ")])]):_c('li',{key:variable.componentId,staticClass:"file-variable",style:({
          left: variable.x * _vm.ratio + 'px',
          top: _vm.calcPositionY(variable.y, variable.page, true) + 'px',
          width: variable.width * _vm.ratio + 'px',
          height: variable.height * _vm.ratio + 'px',
          fontSize:
            variable.fontSize * _vm.ratio >= 12
              ? variable.fontSize * _vm.ratio + 'px'
              : 12 + 'px',
          fontFamily: _vm.getCurFontFamilyByValue(variable.font).name,
        })},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":variable.varValue,"alt":""}})])]})]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }