var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"hasPosition",staticClass:"hasPosition",style:({
  width: _vm.wrapWidth + 'px',
  height: _vm.getImgWrapHeight(_vm.wrapWidth, _vm.imgList.length) + 'px',
})},[_vm._l((_vm.currentPositionList),function(position,index){return [(position.signType == 4)?_vm._l((_vm.pageNum),function(num){return _c('div',{key:position.id + num,staticClass:"hasSign signPosition4",class:{ signTransparent: position.sealKey },style:({
          right: '0px',
          top: _vm.calcPositionY(position.y, num, true) + 'px',
          width: position.sealKey
            ? _vm.getWidthAndHeight(position.width / _vm.pageNum) + 'px'
            : position.width
              ? _vm.getWidthAndHeight(position.width) + 'px'
              : '53px',
          height: position.height
            ? _vm.getWidthAndHeight(position.height) + 'px'
            : '56px',
        })},[(position.sealKey && _vm.canEdit)?_c('i',{staticClass:"el-icon-delete2",on:{"click":function($event){$event.stopPropagation();position.sealKey = null}}}):_vm._e(),(!position.sealKey)?_c('img',{staticClass:"sign-position-default-img",attrs:{"src":_vm.getSignPositionDefaultImgBySignType(position.signType).img,"alt":""}}):_c('div',{staticClass:"img-wrap",style:({
          width: ((_vm.getWidthAndHeight(position.width / _vm.pageNum)) + "px"),
          height: ((_vm.getWidthAndHeight(position.height)) + "px"),
          overflow: "hidden",
        })},[_c('img',{style:({
            width: 'auto',
            height: '100%',
            transform: ("translate(-" + (_vm.getWidthAndHeight(
              position.width / _vm.pageNum
            ) *
              (num - 1)) + "px"),
          }),attrs:{"src":position.sealKey,"alt":""}})])])}):(position.signType === 3)?[_c('div',{key:position.id,staticClass:"hasSign signPosition",class:{ signTransparent: position.sealKey },style:({
        width: position.width
          ? _vm.getWidthAndHeight(position.width) + 'px'
          : '53px',
        height: position.height
          ? _vm.getWidthAndHeight(position.height) + 'px'
          : '56px',
        left: position.x * _vm.ratio + 'px',
        top: _vm.calcPositionY(position.y, position.page, true) + 'px',
      })},[(position.sealKey && _vm.canEdit)?_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();position.sealKey = null}}}):_vm._e(),(position.dateFlag)?_c('div',{staticClass:"time"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.currenDateBase64,"alt":""}})]):_vm._e(),(!position.sealKey)?_c('img',{staticClass:"sign-position-default-img",attrs:{"src":_vm.getSignPositionDefaultImgBySignType(position.signType).img,"alt":""}}):_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":position.sealKey,"alt":""}})])])]:[_c('vue-draggable-resizable',{key:index + 'current',class:{ signTransparent: position.sealKey },attrs:{"class-name":"hasSign signPosition user-sign-position","x":position.x * _vm.ratio,"y":_vm.calcPositionY(position.y, position.page, true),"w":_vm.getWidthAndHeight(position.width),"h":_vm.getWidthAndHeight(position.height),"draggable":false,"resizable":false,"handles":['br']},on:{"resizing":function (left, top, width, height) { return _vm.onSealResizing({
              left: left,
              top: top,
              width: width,
              height: height,
              position: position,
              page: position.page,
            }); },"resizestop":function (left, top, width, height) { return _vm.onSealResizeStop({
              left: left,
              top: top,
              width: width,
              height: height,
              position: position,
              page: position.page,
            }); }}},[_c('div',{staticClass:"seal-wrap"},[(position.sealKey && _vm.canEdit)?_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteSealName(position, index)}}}):_vm._e(),(position.dateFlag)?_c('div',{staticClass:"time"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.currenDateBase64,"alt":""}})]):_vm._e(),(!position.sealKey)?_c('img',{staticClass:"sign-position-default-img",attrs:{"src":_vm.getSignPositionDefaultImgBySignType(position.signType).img,"alt":""}}):_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":position.sealKey,"alt":""}})])])])]]}),_vm._l((_vm.oldPositionList),function(item,index){return [(item.signType == 4)?_vm._l((_vm.pageNum),function(num,index2){return _c('div',{key:index + 'signType4' + index2,class:'signPosition24',style:({
        width: _vm.getWidthAndHeight(item.width / _vm.pageNum) + 'px',
        height: _vm.getWidthAndHeight(item.height) + 'px',
        right: '0px',
        top: _vm.calcPositionY(item.y, num, true) + 'px',
      })},[_c('img',{style:({
          transform: ("translate(-" + (_vm.getWidthAndHeight(item.width / _vm.pageNum) *
            (num - 1)) + "px"),
        }),attrs:{"src":item.sealKey,"alt":""}})])}):[_c('div',{key:index,staticClass:"signPosition2",class:{ 'user-position': _vm.isUserSignType(item.signType) },style:({
        width: _vm.getWidthAndHeight(item.width) + 'px',
        height: _vm.getWidthAndHeight(item.height) + 'px',
        left: item.x * _vm.ratio + 'px',
        top: _vm.calcPositionY(item.y, item.page, true) + 'px',
        // width: `${pxToRem(item.width)}rem`,
        // height: `${pxToRem(item.height)}rem`,
      })},[(item.dateFlag)?_c('div',{staticClass:"time"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.textBecomeImg(item.signDate, 14, '#000'),"alt":""}})]):_vm._e(),_c('img',{attrs:{"src":item.sealKey,"alt":""}})])]]}),_c('ul',{staticClass:"file-img-list"},[_vm._l((_vm.imgList),function(item,index){return _c('li',{key:index,class:_vm.imgList.length > 1 && _vm.type === 2
        ? 'file-img-item seal4-bg'
        : 'file-img-item'},[_c('img',{style:({ height: _vm.computedY + 'px' }),attrs:{"src":item,"alt":""}})])}),(!_vm.currentFile.fileHtml)?[_vm._l((_vm.hasValueParamList),function(variable){return [(variable.dataType != 11)?_c('li',{key:variable.componentId,staticClass:"file-variable",style:({
          left: variable.x * _vm.ratio + 'px',
          top: _vm.calcPositionY(variable.y, variable.page, true) + 'px',
          width:
            variable.fontSize * _vm.ratio >= 12
              ? variable.width * _vm.ratio + 'px'
              : (variable.width * _vm.ratio) /
              ((variable.fontSize * _vm.ratio) / 12) +
              'px',
          height: variable.height * _vm.ratio + 'px',
          fontSize:
            variable.fontSize * _vm.ratio >= 12
              ? variable.fontSize * _vm.ratio + 'px'
              : 12 + 'px',
          fontFamily: _vm.getCurFontFamilyByValue(variable.font).name,
        })},[_c('div',{style:({
            transform: ("scale(" + (variable.fontSize * _vm.ratio >= 12
                ? 1
                : (variable.fontSize * _vm.ratio) / 12) + ")"),
            display: 'inline-block',
            transformOrigin: '0 0',
          })},[_vm._v(" "+_vm._s(variable.varValue ? variable.varValue : variable.varTitle)+" ")])]):_c('li',{key:variable.componentId,staticClass:"file-variable",style:({
          left: variable.x * _vm.ratio + 'px',
          top: _vm.calcPositionY(variable.y, variable.page, true) + 'px',
          width: variable.width * _vm.ratio + 'px',
          height: variable.height * _vm.ratio + 'px',
          fontSize:
            variable.fontSize * _vm.ratio >= 12
              ? variable.fontSize * _vm.ratio + 'px'
              : 12 + 'px',
          fontFamily: _vm.getCurFontFamilyByValue(variable.font).name,
        })},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":variable.varValue,"alt":""}})])]})]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }