<template>
  <div class="hasPosition" ref="hasPosition" :style="{
    width: wrapWidth + 'px',
    height: getImgWrapHeight(wrapWidth, imgList.length) + 'px',
  }">
    <template v-for="(position, index) in currentPositionList">
      <template v-if="position.signType == 4">
        <div class="hasSign signPosition4" :class="{ signTransparent: position.sealKey }" v-for="num in pageNum"
          :key="position.id + num" :style="{
            right: '0px',
            top: calcPositionY(position.y, num, true) + 'px',
            width: position.sealKey
              ? getWidthAndHeight(position.width / pageNum) + 'px'
              : position.width
                ? getWidthAndHeight(position.width) + 'px'
                : '53px',
            height: position.height
              ? getWidthAndHeight(position.height) + 'px'
              : '56px',
          }">
          <i class="el-icon-delete2" @click.stop="position.sealKey = null" v-if="position.sealKey && canEdit"></i>
          <img v-if="!position.sealKey" class="sign-position-default-img"
            :src="getSignPositionDefaultImgBySignType(position.signType).img" alt="" />
          <div class="img-wrap" :style="{
            width: `${getWidthAndHeight(position.width / pageNum)}px`,
            height: `${getWidthAndHeight(position.height)}px`,
            overflow: `hidden`,
          }" v-else>
            <img :src="position.sealKey" alt="" :style="{
              width: 'auto',
              height: '100%',
              transform: `translate(-${getWidthAndHeight(
                position.width / pageNum
              ) *
                (num - 1)}px`,
            }" />
          </div>
        </div>
      </template>

      <!-- 签章 -->
      <template v-else-if="position.signType === 3">
        <div class="hasSign signPosition" :class="{ signTransparent: position.sealKey }" :key="position.id" :style="{
          width: position.width
            ? getWidthAndHeight(position.width) + 'px'
            : '53px',
          height: position.height
            ? getWidthAndHeight(position.height) + 'px'
            : '56px',
          left: position.x * ratio + 'px',
          top: calcPositionY(position.y, position.page, true) + 'px',
        }">
          <i class="el-icon-delete" @click.stop="position.sealKey = null" v-if="position.sealKey && canEdit"></i>
          <div v-if="position.dateFlag" class="time">
            <img class="currenDateBase64" :src="currenDateBase64" alt="" />
          </div>
          <img v-if="!position.sealKey" class="sign-position-default-img"
            :src="getSignPositionDefaultImgBySignType(position.signType).img" alt="" />
          <div class="img-wrap" v-else>
            <img :src="position.sealKey" alt="" />
          </div>
        </div>
      </template>
      <!-- 签章 -->

      <!-- 签名 可以缩放 -->
      <template v-else>
        <vue-draggable-resizable class-name="hasSign signPosition user-sign-position"
          :class="{ signTransparent: position.sealKey }" :key="index + 'current'" :x="position.x * ratio"
          :y="calcPositionY(position.y, position.page, true)" :w="getWidthAndHeight(position.width)"
          :h="getWidthAndHeight(position.height)" :draggable="false" :resizable="false" :handles="['br']" @resizing="(left, top, width, height) =>
              onSealResizing({
                left,
                top,
                width,
                height,
                position,
                page: position.page,
              })
            " @resizestop="(left, top, width, height) =>
              onSealResizeStop({
                left,
                top,
                width,
                height,
                position,
                page: position.page,
              })
            ">
          <div class="seal-wrap">
            <i class="el-icon-delete" @click.stop="handleDeleteSealName(position, index)"
              v-if="position.sealKey && canEdit"></i>
            <div v-if="position.dateFlag" class="time">
              <img class="currenDateBase64" :src="currenDateBase64" alt="" />
            </div>
            <!-- 默认签署位置图片 -->
            <img v-if="!position.sealKey" class="sign-position-default-img"
              :src="getSignPositionDefaultImgBySignType(position.signType).img" alt="" />
            <!-- 默认签署位置图片 -->

            <div class="img-wrap" v-else>
              <img :src="position.sealKey" alt="" />
            </div>
            <!--            <div class="signTitle">
              <img
                class="currenDateBase64"
                :src="
                  textBecomeImg(
                    SIGN_SEAL_TYPE_TEXT[position.signType],
                    14,
                    '#000'
                  )
                "
                alt=""
              />
            </div>-->
          </div>
        </vue-draggable-resizable>
      </template>
      <!-- 签名 可以缩放 -->
    </template>
    <template v-for="(item, index) in oldPositionList">
      <template v-if="item.signType == 4">
        <div :class="'signPosition24'" v-for="(num, index2) in pageNum" :key="index + 'signType4' + index2" :style="{
          width: getWidthAndHeight(item.width / pageNum) + 'px',
          height: getWidthAndHeight(item.height) + 'px',
          right: '0px',
          top: calcPositionY(item.y, num, true) + 'px',
        }">
          <img :src="item.sealKey" alt="" :style="{
            transform: `translate(-${getWidthAndHeight(item.width / pageNum) *
              (num - 1)}px`,
          }" />
        </div>
      </template>
      <template v-else>
        <div :class="{ 'user-position': isUserSignType(item.signType) }" class="signPosition2" :key="index" :style="{
          width: getWidthAndHeight(item.width) + 'px',
          height: getWidthAndHeight(item.height) + 'px',
          left: item.x * ratio + 'px',
          top: calcPositionY(item.y, item.page, true) + 'px',
          // width: `${pxToRem(item.width)}rem`,
          // height: `${pxToRem(item.height)}rem`,
        }">
          <div v-if="item.dateFlag" class="time">
            <img class="currenDateBase64" :src="textBecomeImg(item.signDate, 14, '#000')" alt="" />
          </div>
          <img :src="item.sealKey" alt="" />
        </div>
      </template>
    </template>
    <ul class="file-img-list">
      <li :class="imgList.length > 1 && type === 2
          ? 'file-img-item seal4-bg'
          : 'file-img-item'
        " v-for="(item, index) in imgList" :key="index">
        <img :src="item" alt="" :style="{ height: computedY + 'px' }" />
      </li>
      <template v-if="!currentFile.fileHtml">
        <template v-for="variable in hasValueParamList">
          <li class="file-variable" v-if="variable.dataType != 11" :key="variable.componentId" :style="{
            left: variable.x * ratio + 'px',
            top: calcPositionY(variable.y, variable.page, true) + 'px',
            width:
              variable.fontSize * ratio >= 12
                ? variable.width * ratio + 'px'
                : (variable.width * ratio) /
                ((variable.fontSize * ratio) / 12) +
                'px',
            height: variable.height * ratio + 'px',
            fontSize:
              variable.fontSize * ratio >= 12
                ? variable.fontSize * ratio + 'px'
                : 12 + 'px',
            fontFamily: getCurFontFamilyByValue(variable.font).name,
          }">
            <div :style="{
              transform: `scale(${variable.fontSize * ratio >= 12
                  ? 1
                  : (variable.fontSize * ratio) / 12
                })`,
              display: 'inline-block',
              transformOrigin: '0 0',
            }">
              {{ variable.varValue ? variable.varValue : variable.varTitle }}
            </div>
          </li>

          <li class="file-variable" v-else :key="variable.componentId" :style="{
            left: variable.x * ratio + 'px',
            top: calcPositionY(variable.y, variable.page, true) + 'px',
            width: variable.width * ratio + 'px',
            height: variable.height * ratio + 'px',
            fontSize:
              variable.fontSize * ratio >= 12
                ? variable.fontSize * ratio + 'px'
                : 12 + 'px',
            fontFamily: getCurFontFamilyByValue(variable.font).name,
          }">
              <img :src="variable.varValue" alt="" style="width: 100%;height: 100%;" />

          </li>
        </template>
      </template>
    </ul>
  </div>
</template>
<script>
import { getCurFontFamilyByValue } from '@/common/font'
import { mapState } from 'vuex'
import { ImagePreview } from 'vant'
import moment from 'moment'
import { translateSealWidthAndHeight } from '@/utils/pxtorem'
import textBecomeImg from '@/utils/textBecomeImg'
import {
  getBodyClientRect,
  getFileImgHeight,
  getImgWrapHeight,
} from '@/utils/dom'
import { paginationHeight } from '@/common/filePreviewConfig'
import { isEmptyVariableValue } from '@/utils/helper'
import { isUserSignType } from '@/common/userSignatureConfig'

import signPositionDefaultImgPersonal from '@/assets/imgs/signFile/sign_position_personal.png'
import signPositionDefaultImgHandler from '@/assets/imgs/signFile/sign_position_handler.png'
import signPositionDefaultImgLegal from '@/assets/imgs/signFile/sign_position_legal.png'
import signPositionDefaultImgSeal from '@/assets/imgs/signFile/sign_position_seal.png'
import signPositionDefaultImgRiding from '@/assets/imgs/signFile/sign_position_seal_riding.png'

const signPositionDefaultImg = [
  // 个人签名
  {
    type: 0,
    img: signPositionDefaultImgPersonal,
  },
  // 经办人签署
  {
    type: 1,
    img: signPositionDefaultImgHandler,
  },
  // 法人签署
  {
    type: 2,
    img: signPositionDefaultImgLegal,
  },
  // 企业签章页面章
  {
    type: 3,
    img: signPositionDefaultImgSeal,
  },
  // 企业签章骑缝章
  {
    type: 4,
    img: signPositionDefaultImgRiding,
  },
]

export default {
  props: {
    ratio: {
      type: Number,
      default: 343 / 595,
    },
    // 发起时的当前文件
    launchCurrentFile: {
      type: Object,
      default: () => ({}),
    },
    curLauncher: {
      type: Object,
      default: () => ({}),
    },

    // 能否编辑，删除等操作 (zdd 不能操作，直接盖章)
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currenDate: moment().format('YYYY-MM-DD'),
      currenDateBase64: textBecomeImg(
        moment().format('YYYY-MM-DD'),
        14,
        '#000'
      ),
      wrapTop: 0,
      wrapWidth: 0,
      wrapHeight: 0,
      SIGN_SEAL_TYPE_TEXT: {
        0: '个人签署',
        1: '经办人签署',
        2: '法人签署',
        3: '企业签章',
        4: '骑缝章',
      },
      masterId: undefined,
      baseImageUrl: `${process.env.VUE_APP_OSS_URL}/`,
    }
  },
  computed: {
    ...mapState({
      currentFile: state => state.contractSign.currentFile,
      ratio2: state => state.contractSign.ratio2,
      computedY: state => state.contractSign.computedY,
      operateType: state => state.contractSign.operateType,
    }),

    fileHtml() {
      if (this.masterId) {
        return this.launchCurrentFile.fileHtml
      }
      return this.currentFile.fileHtml
    },

    oldPositionList() {
      // 发起的时候是不会有已经签好的章的
      if (this.masterId) {
        return this.launchCurrentFile.oldPositionList
      }
      return this.currentFile.oldPositionList
    },
    currentPositionList() {
      // 发起，筛选出当前发起方应该要签署的签章list
      if (this.masterId) {
        return this.curLauncherPositionList
      }
      return this.currentFile.currentPositionList
    },

    curLauncherPositionList() {
      if (this.curLauncher && this.curLauncher.partnerIndex) {
        const curLauncherIndex = this.curLauncher.partnerIndex
        if (
          this.launchCurrentFile &&
          this.launchCurrentFile.signPositionList
        ) {
          const curPartnerSignPosition = this.launchCurrentFile.signPositionList.filter(
            signPosition => signPosition.partnerIndex === curLauncherIndex
          )
          const arr = []
          if (curPartnerSignPosition.length > 0) {
            curPartnerSignPosition.forEach(x => {
              arr.push(...x.positionList)
            })
          }
          // const temp = this.setSealDefault(arr)
          return arr
        }
      }
      return []
    },
    imgList() {
      if (this.masterId) {
        return this.launchCurrentFile.imgList || []
      }
      return this.currentFile.fileKeyList || []
    },
    type() {
      if (
        this.operateType === 1 ||
        this.operateType === 2 ||
        this.operateType === 3
      ) {
        return 1
      }
      return 2
    },
    pageNum() {
      if (this.masterId) {
        return this.imgList.pageNum
      }
      return this.currentFile.pageNum
    },
    hasValueParamList() {
      const varList = this.masterId
        ? this.launchCurrentFile?.comVarParamList
        : this.currentFile?.allVarList
      if (varList) {
        return varList.filter(param => !isEmptyVariableValue(param.varValue))
      }
      return []
    },
  },
  created() {
    this.masterId = this.$route.query.masterId
  },
  mounted() {
    const { clientWidth } = getBodyClientRect()
    const height = getFileImgHeight()
    this.wrapWidth = clientWidth
    this.wrapHeight = height
  },
  methods: {
    getImgWrapHeight,
    getCurFontFamilyByValue,
    textBecomeImg,
    isUserSignType,
    getSignPositionDefaultImgBySignType(signType) {
      return (
        signPositionDefaultImg.find(
          signPosition => signPosition.type === signType
        ) || {}
      )
    },
    getWidthAndHeight(value) {
      const { clientWidth } = getBodyClientRect()
      this.wrapWidth = clientWidth
      return this.translateSealWidthAndHeight(this.wrapWidth, value)
    },
    translateSealWidthAndHeight,
    showImg(index) {
      ImagePreview({
        images: this.imgList,
        startPosition: index,
        closeOnPopstate: true,
      })
    },

    getCurrentPage(y) {
      const disY = y
      if (disY < this.computedY) {
        return {
          page: 1,
          y,
        }
      }
      return {
        page: Math.floor(disY / this.computedY) + 1,
        y: disY % this.computedY,
      }
    },

    onSealResizing({ left, top, width, height, item, page: currentPage }) {
      item.resize = true
      top -= (currentPage - 1) * paginationHeight
      this.$emit('pzDisabled')
      const { y, page } = this.getCurrentPage(top)
      // item.x = item.originalX
      // item.y = item.originalY
      // console.log(item.x, item.y)
      item.page = page
      item.width = width > 0 ? width : 0
      item.height = height > 0 ? height : 0
      console.log(item.width, item.height)
      item.wrapWidth = this.wrapWidth
      item.changeFlag = 1
    },

    // 缩放完毕 要计算边界问题
    onSealResizeStop({ left, top, width, height, item, page: currentPage }) {
      console.log(width, height)
      top -= (currentPage - 1) * paginationHeight
      this.$emit('pzEnabled')
      const { y } = this.getCurrentPage(top)
      // 超出一页高度 要还原
      if (y + height + 20 > this.wrapHeight) {
        item.height = this.wrapHeight - 20 - y
      } else {
        item.height = height > 0 ? height : 0
      }
      // 超出一页宽度 要还原
      if (left + width > this.wrapWidth) {
        //
        item.width = this.wrapWidth - left
      }
      // if (height < 38) {
      //   item.height = 38
      // }
      // if (width < 93) {
      //   item.width = 93
      // }

      item.x = item.originalX
      item.y = item.originalY
      console.log(item.width, item.height)
    },

    handleDeleteSealName(item, index) {
      const { originalX, originalY } = item
      item.sealKey = null
      item.sealImg = null
      item.sealId = null
      // item.width = null
      // item.height = null
      item.x = originalX
      item.y = originalY
      item.resize = false
    },

    calcPositionY(y, page, ratio) {
      if (ratio) {
        return (
          y * this.ratio +
          (page - 1) * (this.computedY + paginationHeight * this.ratio2)
        )
      }
      return (
        y + (page - 1) * (this.computedY + paginationHeight * this.ratio2)
      )
    },
  },
}
</script>
<style lang="less" scoped>
.user-position-style {
  background: rgba(233, 249, 243, 0.82);
  border: 1px dashed #61d0aa;
}

.hasPosition {
  position: relative;

  // padding: 0 16px;
  .file-img-list {
    position: relative;
    width: 100%;

    .file-img-item {
      width: 100%;
      // display: flex;
      position: relative;

      img {
        width: 100%;
      }
    }

    .file-img-item+.file-img-item {
      margin-top: 20px;
    }

    .seal4-bg {
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        content: '骑缝章签署区';
        width: 50px;
        // height: 100%;
        color: #95befd;
        background: url('../../../assets/imgs/sealManage/seal_bg1.svg');
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
        font-size: 28px;
      }
    }

    .file-variable {
      position: absolute;
      display: flex;
    }
  }

  .signPosition {
    position: absolute;
    background: rgba(255, 249, 218, 0.6);
    border: 1px dashed #ffc000;
    color: #111a34;
    // color: #ff9400;
    // background: rgba(3, 113, 255, 0.07);
    // border: 1px dashed #1676ff;
    // color: #1676ff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2 !important;

    .seal-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .sign-position-default-img {
        padding: 8px 6px;
      }
    }

    .img-wrap {
      position: relative;
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .time {
      background: rgba(255, 249, 218, 0.6);
      border: 1px dashed #ffc000;
    }

    &.user-sign-position {
      .user-position-style();

      .time {
        .user-position-style();
      }
    }
  }

  .signPosition4 {
    position: absolute;
    z-index: 2 !important;
    width: 53px;
    height: 56px;
    background: rgba(255, 249, 218, 0.6);
    border: 1px dashed #ffc000;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    display: flex;
    align-items: center;

    .img-wrap {
      height: 100%;
    }

    img {
      height: 100%;
    }
  }

  .signPosition2 {
    position: absolute;
    z-index: 1;
    //width: 106px !important;
    //height: 56px !important;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    // color: #ff9400;
    line-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .signPosition24 {
    position: absolute;
    z-index: 1;
    // width: 53px !important;
    // height: 56px !important;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #5f6368;

    // color: #ff9400;
    line-height: 56px;
    overflow: hidden;
    display: flex;
    // justify-content: center;
    align-items: center;

    img {
      height: 100%;
    }
  }

  .sealStyle {
    width: 86px;
    height: 86px;
    background: rgba(3, 113, 255, 0.07);
    border: 1px dashed #1676ff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #1676ff;
    position: absolute;

    .img-wrap {
      width: 86px;
      height: 86px;
      line-height: 86px;
      overflow: hidden;
    }

    img {
      width: 72px;
      height: 72px;
    }
  }

  .sealStyle4 {
    width: 43px;
    height: 86px;
    background: #f4f5f6;
    border: 1px dashed #f15643;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    position: absolute;
    z-index: 20;

    .img-wrap {
      display: block;
      width: 43px;
      height: 86px;
      line-height: 86px;
      overflow: hidden;
    }

    img {
      width: 72px;
      height: 72px;
      margin-right: -36px;
    }
  }

  .sealStyle2 {
    width: 86px;
    height: 86px;
    background: transparent;
    border: 1px dashed #677283;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    // overflow: hidden;
    position: absolute;

    img {
      width: 72px;
      height: 72px;
    }
  }

  .sealStyle24 {
    width: 43px;
    height: 86px;
    background: transparent;
    border: 1px dashed #f15643;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    overflow: hidden;
    position: absolute;

    img {
      width: 72px;
      height: 72px;
      margin-right: -36px;
    }
  }

  .el-icon-delete {
    background: url('../../../assets/imgs/signFile/delete.png') no-repeat center;
    width: 12px;
    height: 12px;
    background-size: 100%;
    position: absolute;
    top: -8px;
    left: -6px;
    z-index: 100;
    cursor: pointer;
  }

  .el-icon-delete2 {
    background: url('../../../assets/imgs/signFile/delete.png') no-repeat center;
    width: 12px;
    height: 12px;
    background-size: 100%;
    position: absolute;
    top: -8px;
    left: -6px;
    z-index: 100;
    cursor: pointer;
  }

  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    bottom: -20px;
    left: -1px;
    right: -1px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    font-size: 10px;
    padding: 0 4px;

    .currenDateBase64 {
      position: relative;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    .el-icon-delete {
      bottom: -6px;
      top: initial;
    }
  }

  .signTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    position: absolute;
    top: -15px;
    left: -1px;
    right: -1px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    font-size: 10px;
    background: rgba(255, 249, 218, 0.6);
    padding: 0 4px;
    border-left: 0.02667rem dashed #ffc000;
    border-top: 0.02667rem dashed #ffc000;
    border-right: 0.02667rem dashed #ffc000;

    .currenDateBase64 {
      position: relative;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .signPosition4 .signTitle {
    border-color: #1676ff;
    background: transparent;
  }
}
</style>
